import React from 'react'
import { Heading, Text, TextContainer } from '../components/shared/Typography'

const NotFoundPage = () => (
  <TextContainer
    style={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Heading>Whoops - That Page Doesn’t Exist (404)</Heading>
    <Text>
      Looks like the page you requested either doesn’t exist or has been moved.
      If you think this is an error or ended up at this page by following a
      link, please email us{' '}
      <a href='mailto:cs@uvsoldier.com'>cs@uvsoldier.com</a>.
    </Text>
    <Text>
      <a href='https://www.uvsoldier.com/'>UV-Soldier Online Store</a>
    </Text>
  </TextContainer>
)

export default NotFoundPage
